import { TrayHelpers } from "@mixins/integrations/tray-helpers"
import { ConnectionUseCase, SubscriptionTypes } from "@mixins/connections"
import {
  DataSources,
  freeDataSources,
  premiumDataSources,
  IntegrationDataSources,
} from "@mixins/data-sources"
import StaticHelper from "@mixins/static-helpers"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import axios from "axios"
import router from "@router"
import Vue from "vue"
import { AppContexts } from "@/src/utils/mixins/app-contexts"

export const state = {
  ...BaseState,
  connections: null,
  connectionsCache: {},
  wordsCache: [],
  searchTerm: null,
  connectionSheetSearchTerm: null,
  activeConnection: null,
  activeScope: null,
  activeFilter: null,
  activeSection: null,
  scopes: ["All", "Shared", "Received"],
  filters: [
    { name: "Activity", attr: "all" },
    { name: "Companies", attr: "company" },
    { name: "Reports", attr: "report" },
    { name: "Other", attr: ["web_link", "attachment"] },
  ],
  transitionFilter: null,
}

export const getters = {
  ...BaseGetters,
  connections: (state, getters) => {
    if (getters.connectionSheetSearchTerm) {
      const regex = new RegExp(getters.connectionSheetSearchTerm, "i")
      return _.filter(
        _.cloneDeep(state.connections),
        (c) => regex.test(c.name) || regex.test(c.network_name)
      )
    } else {
      return state.connections
    }
  },
  connectionsCache: (state) => {
    return state.connectionsCache
  },
  wordsCache: (state) => {
    return state.wordsCache
  },
  connectionsIndexObject: (state) => {
    return _.find(state.connections, "all")
  },
  activeGroupConnection: (state, getters, rootState, rootGetters) => {
    let output = []
    const activeGroup = rootGetters["auth/activeGroup"]
    if (!activeGroup) return output
    let agc = _.find(getters.connections, {
      model_type: "Group",
      id: _.get(activeGroup, "id"),
    })
    if (agc) output.push(agc)
    return output
  },
  networkConnections: (state, getters, rootState, rootGetters) => {
    // NOTE - this only includes other groups or the network,
    // and not the activeGroup connection to itself.

    let output = []

    const activeNetwork = rootGetters["auth/activeNetwork"]

    const activeNetworkConnection = _.find(getters.connections, {
      model_type: "Network",
      id: _.get(activeNetwork, "id"),
    })

    if (!_.isEmpty(activeNetworkConnection)) {
      output.push({
        items: [activeNetworkConnection],
      })
    }

    let groups = []

    const activeNetworkGroups = _.orderBy(
      _.filter(getters.connections, (c) => {
        return (
          c.use_case === ConnectionUseCase.GROUP_SUBSCRIPTIONS &&
          c.model_type === "Group" &&
          c.network_id === activeNetwork.id
        )
      }),
      ["subscription_type", "name"],
      ["asc", "asc"]
    )

    groups = groups.concat(activeNetworkGroups)

    if (!_.isEmpty(groups)) {
      output.push({
        items: groups,
      })
    }

    return output
  },
  flatPartnerConnections: (state, getters, rootState, rootGetters) => {
    if (_.isEmpty(getters.connections)) return []
    return _.filter(getters.connections, (c) => {
      return c.use_case === ConnectionUseCase.GROUP_SUBSCRIPTIONS
    })
  },
  integrationDataConnections: (getters) => {
    if (_.isEmpty(getters.connections)) return []
    return _.filter(getters.connections, (c) => {
      return IntegrationDataSources.includes(c.data_source)
    })
  },
  partnerConnections: (state, getters, rootState, rootGetters) => {
    if (_.isEmpty(getters.connections)) {
      return []
    }

    let output = []

    // Build Subscription Sections
    const activeNetworkId = _.get(rootGetters["auth/activeNetwork"], "id")

    const allCon = _.filter(getters.connections, (c) => {
      return (
        c.use_case === ConnectionUseCase.GROUP_SUBSCRIPTIONS &&
        ((c.model_type === "Group" && c.network_id !== activeNetworkId) ||
          (c.model_type === "Network" && c.id !== activeNetworkId))
      )
    })

    const sectionNetworkIds = _.uniq(
      _.map(allCon, (c) => {
        return (
          (c.model_type === "Group" && c.network_id) ||
          (c.model_type === "Network" && c.id)
        )
      })
    )

    _.forEach(sectionNetworkIds, (nId) => {
      const sectionItems = _.filter(allCon, (c) => {
        return (
          (c.model_type === "Group" && c.network_id === nId) ||
          (c.model_type === "Network" && c.id === nId)
        )
      })

      if (!_.isEmpty(sectionItems)) {
        const firstCard = _.first(sectionItems)
        output.push({
          header: {
            name: _.get(firstCard, "network_name") || _.get(firstCard, "name"),
            logo: _.get(firstCard, "logo"),
          },
          items: _.orderBy(
            sectionItems,
            ["model_type", "subscription_type", "name"],
            ["desc", "asc", "asc"]
          ),
        })
      }
    })

    output = _.sortBy(output, [
      function (s) {
        return _.get(s, "header.name")
      },
    ])

    return output
  },
  dataConnections: (state, getters, rootState, rootGetters) => {
    if (_.isEmpty(getters.connections)) {
      return []
    }

    const output = []
    const activeIntegrations = rootGetters["auth/activeIntegrations"]
    const freeDataProviders = _.orderBy(
      _.filter(getters.connections, (c) => {
        return freeDataSources.includes(c.data_source)
      }),
      ["name"],
      ["asc"]
    )

    // Data Sources
    if (!_.isEmpty(freeDataProviders)) {
      output.push({
        header: {
          icon: "call_merge",
          name: "Data Providers",
          tooltip: "Populate company profiles with firmographic data",
        },
        items: freeDataProviders,
      })
    }

    //Premium Data Sources
    const premiumDataProviders = _.orderBy(
      _.filter(getters.connections, (c) => {
        return premiumDataSources.includes(c.data_source)
      }),
      ["name"],
      ["asc"]
    )

    // Data Sources
    if (!_.isEmpty(premiumDataProviders)) {
      output.push({
        header: {
          icon: "call_merge",
          name: "Premium Data Providers",
          tooltip:
            "Link your account to populate companies with firmographic info.",
        },
        items: premiumDataProviders,
      })
    }

    // News
    const news = []

    // Twitter Integration (hardcoded for now)
    if (activeIntegrations.twitter && !getters.connectionSheetSearchTerm) {
      news.push({
        id: "twitter",
        provider: "twitter",
        use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
        subscription_type: 4,
        name: "X",
        logo: require("@images/providers/twitter-icon.svg"),
        branding_color: "rgb(29, 161, 242)",
        email: null,
        text: "Browse social media feeds.",
        link: "https://www.nucla.com/connections/twitter",
        latest_activity: new Date(),
        psuedo_disabled: true,
        news_provider: true,
      })
    }

    if (!_.isEmpty(news)) {
      output.push({
        header: {
          icon: "article",
          name: "News",
          tooltip: "Stay current on news and activity with real time updates",
        },
        items: news,
      })
    }

    // Available Integrations
    if (activeIntegrations) {
      let disabledItems = []

      if (!activeIntegrations.clearbit) {
        disabledItems.push({
          provider: "clb",
          use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
          name: "Clearbit",
          logo: "https://ryse-static.s3.amazonaws.com/logos/clearbit-icon.svg",
          branding_color: "#4db1fd",
          email: "support@clearbit.com",
          text: "Enrich existing companies with data such as: sector, amount raised, estimated annual revenue, and more. Browse a feed of new and historical activity.",
          link: "https://www.nucla.com/connections/clearbit",
          disabled: true,
        })
      }

      if (!activeIntegrations.crunchbase) {
        disabledItems.push({
          provider: "cb",
          use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
          name: "Crunchbase",
          logo: "https://ryse-static.s3.amazonaws.com/logos/crunchbase-icon.svg",
          branding_color: "#146aff",
          email: "support@crunchbase.com",
          text: "Enrich existing companies with data such as: investors, categories, funding rounds, and more. Browse a feed of new and historical activity.",
          link: "https://www.nucla.com/connections/crunchbase",
          disabled: true,
        })
      }

      // if (!activeIntegrations.similarweb) {
      //   disabledItems.push({
      //     provider: "sw",
      //     use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
      //     name: "SimilarWeb",
      //     logo: "https://ryse-static.s3.amazonaws.com/logos/similar-web-logo.png",
      //     branding_color: "#202c55",
      //     email: "support@similarweb.com",
      //     text: "Enrich existing companies with website traffic intelligence such as: similar sites, geographic visit distribution, global traffic rank, and more. Browse a feed of new and historical activity.",
      //     link: "https://www.nucla.com/connections/similar-web",
      //     disabled: true,
      //   })
      // }

      if (!activeIntegrations.pitchbook) {
        disabledItems.push({
          provider: "pb",
          use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
          name: "PitchBook",
          logo: "https://ryse-static.s3.amazonaws.com/logos/pitchbook-icon.svg",
          branding_color: "#4e6b8f",
          email: "support@pitchbook.com",
          text: "Connect your existing PitchBook account to enrich your companies with the premier provider of private market data.",
          link: "https://www.nucla.com/connections/pitchbook",
          disabled: true,
        })
      }

      if (!activeIntegrations.owler) {
        disabledItems.push({
          provider: "owl",
          use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
          name: "Owler",
          logo: "https://ryse-static.s3.amazonaws.com/logos/owler-logo.png",
          branding_color: "#0ba2a2",
          email: "support@owler.com",
          text: "Enrich existing companies with data such as: industries, acquisitions, funding, and more. Browse a feed of new and historical activity.",
          link: "https://www.nucla.com/connections/owler",
          news: {
            text: "Enrich existing companies with recent news including: funding events, new product launches, partnerships and more.",
            link: "https://www.nucla.com/connections/owler",
          },
          disabled: true,
        })
      }

      if (!activeIntegrations.twitter) {
        disabledItems.push({
          provider: "twitter",
          use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
          name: "X",
          logo: require("@images/providers/twitter-icon.svg"),
          branding_color: "rgb(29, 161, 242)",
          email: null,
          text: "Bring in tweets and alerts to stay current on companies of interest.",
          link: "https://www.nucla.com/connections/twitter",
          disabled: true,
        })
      }

      if (!_.isEmpty(disabledItems)) {
        output.push({
          header: {
            limited: true,
            name: "Available Integrations",
          },
          items: disabledItems,
        })
      }
    }

    return output
  },
  integrationConnections: (state, getters, rootState, rootGetters) => {
    if (_.isEmpty(getters.connections)) {
      return []
    }

    const output = []
    const activeIntegrations = rootGetters["auth/activeIntegrations"]

    // Form Connections
    const formConnections = _.cloneDeep(
      _.filter(getters.connections, (c) => {
        return c.data_source === DataSources.TYPEFORM && c.integration_parent
      })
    )

    if (!_.isEmpty(formConnections)) {
      output.push({
        header: {
          icon: "dynamic_form",
          name: "Forms",
          tooltip:
            "Pull in company submissions and responses from your surveys and forms.",
        },
        items: formConnections,
      })
    }

    // CRM
    const allTrayConnections = _.filter(getters.connections, (connection) => {
      return TrayHelpers.isTrayProvider(connection.word)
    })
    if (!_.isEmpty(allTrayConnections)) {
      // We must determine if the user should be able to see the connection card via
      // 1. They have the integration enabled
      // 2. They are a recipient of CRM data
      let trayConnections = []
      for (var i = 0; i < allTrayConnections.length; i++) {
        // Integration is enabled
        if (activeIntegrations[allTrayConnections[i].id]) {
          trayConnections.push(allTrayConnections[i])
        } else {
          let sharedTrayConnection = _.find(getters.connections, (c) => {
            let isSameProvider = c.provider === allTrayConnections[i].provider
            let hasParent = !!c.parent_id
            return isSameProvider && hasParent
          })
          // Group is recipient of shared CRM data
          if (!_.isEmpty(sharedTrayConnection)) {
            trayConnections.push(allTrayConnections[i])
          }
        }
      }

      if (!_.isEmpty(trayConnections)) {
        output.push({
          header: {
            icon: "device_hub",
            name: "CRM",
            tooltip:
              "Pull in the latest companies and field data from any CRM you have access to",
          },
          items: trayConnections,
        })
      }
    }

    // Available Integrations
    if (activeIntegrations) {
      let disabledItems = []

      if (!activeIntegrations.salesforce) {
        disabledItems.push({
          provider: "salesforce",
          use_case: ConnectionUseCase.GROUP_TRAY_INTEGRATIONS,
          name: "Salesforce",
          logo: "https://ryse-static.s3.amazonaws.com/logos/salesforce.png",
          branding_color: "#17A0DB",
          email: null,
          text: "Connect Salesforce accounts to enrich company data.",
          link: "https://www.nucla.com/connections/salesforce",
          disabled: true,
        })
      }

      if (!activeIntegrations.microsoft_dynamics_365) {
        disabledItems.push({
          provider: "microsoft_dynamics_365",
          use_case: ConnectionUseCase.GROUP_TRAY_INTEGRATIONS,
          name: "Microsoft Dynamics 365",
          logo: "https://ryse-static.s3.amazonaws.com/logos/microsoft-dynamics-365.png",
          branding_color: "#4054BB",
          email: null,
          text: "Connect Microsoft Dynamics 365 accounts to enrich company data.",
          link: "https://www.nucla.com/connections/microsoft-dynamics-365",
          disabled: true,
        })
      }

      if (!activeIntegrations.affinity) {
        disabledItems.push({
          provider: "affinity",
          use_case: ConnectionUseCase.GROUP_TRAY_INTEGRATIONS,
          name: "Affinity",
          logo: "https://ryse-static.s3.amazonaws.com/logos/affinity.png",
          branding_color: "#2265f1",
          email: null,
          text: "Connect Affinity accounts to enrich company data.",
          link: "https://www.nucla.com/connections/affinity",
          disabled: true,
        })
      }

      if (!activeIntegrations.typeform) {
        disabledItems.push({
          provider: "typeform",
          use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
          name: "Typeform",
          logo: "https://ryse-static.s3.amazonaws.com/logos/typeform-icon.svg",
          branding_color: "#f5f1ea",
          email: null,
          text: "Automatically import company responses from your existing forms.",
          link: "https://www.nucla.com/connections/typeform",
          disabled: true,
        })
      }

      if (!activeIntegrations.pigeonhole) {
        disabledItems.push({
          provider: "pigeonhole",
          use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
          name: "Pigeonhole",
          logo: "https://ryse-static.s3.amazonaws.com/logos/pigeonhole.png",
          branding_color: "#f39124",
          email: null,
          text: "Live Q&As, polls, quizzes, and surveys to engage your audience at meetings and virtual events.",
          link: "https://www.nucla.com/connections/pigeonhole",
          disabled: true,
        })
      }

      if (!activeIntegrations.youtube) {
        disabledItems.push({
          provider: "youtube",
          use_case: ConnectionUseCase.GROUP_INTEGRATIONS,
          name: "YouTube",
          logo: "https://ryse-static.s3.amazonaws.com/logos/youtube.png",
          branding_color: "#FF0000",
          email: null,
          text: "Add videos and livestreams to dashboards.",
          link: "https://www.nucla.com/connections/youtube",
          disabled: true,
        })
      }

      if (!_.isEmpty(disabledItems)) {
        output.push({
          header: {
            limited: true,
            name: "Available Integrations",
          },
          items: disabledItems,
        })
      }
    }

    return output
  },
  importConnections: (state, getters, rootState, rootGetters) => {
    return _.sortBy(
      _.filter(getters.connections, (c) => {
        return (
          [
            ConnectionUseCase.GROUP_INTEGRATIONS,
            ConnectionUseCase.GROUP_TRAY_INTEGRATIONS,
          ].includes(c.use_case) &&
          [
            "typeform",
            "flatfile",
            "salesforce",
            "microsoft_dynamics_365",
            "affinity",
          ].includes(c.id)
        )
      }),
      "name"
    )
  },
  filters: (state) => {
    // All, Companies, Reports, Links, Files
    return state.filters
  },
  scopes: (state) => {
    // All, Shared, Received
    return state.scopes
  },
  activeConnection: (state) => {
    return state.activeConnection
  },
  activeScope: (state) => {
    return state.activeScope
  },
  activeFilter: (state) => {
    return state.activeFilter
  },
  searchTerm: (state) => {
    return state.searchTerm
  },
  connectionSheetSearchTerm: (state) => {
    return state.connectionSheetSearchTerm
  },
  dataSourceFilterOptions: (state, getters, rootState, rootGetters) => {
    return _.filter(
      state.connections,
      (c) => !!c.word && !TrayHelpers.isTrayProvider(c.word)
    )
  },
  connectionCounts: (state, getters, rootState, rootGetters) => {
    const group = rootGetters["auth/activeGroup"]
    if (!group) {
      return {}
    }
    const counter = (p, c) => {
      p += _.get(c.items, "length", 0)
      return p
    }

    const integrationCounter = (p, c) => {
      let items = c.items
      if (!items.length) return p
      const enabled_count = _.filter(items, (i) => !i.disabled).length || 0
      return (p += enabled_count)
    }

    const countBySubscriptionType = (arr, subType) => {
      let count = 0
      for (let i = 0; i < arr.length; ++i) {
        const subArr = arr[i].items
        if (subArr && subArr.length) {
          for (let j = 0; j < subArr.length; ++j) {
            const item = subArr[j]
            if (
              (item && subType.includes(item.subscription_type)) ||
              isSelfSubForActiveGroup(item)
            ) {
              count += 1
            }
          }
        }
      }
      return count
    }

    const isSelfSubForActiveGroup = (con) => {
      return (
        con.use_case === ConnectionUseCase.GROUP_ACTIVE &&
        group.id == con.id &&
        con.model_type == "Group"
      )
    }

    return {
      totals: {
        unique_connections: _.get(group, "connections_count", 0),
        internal: _.get(getters, "networkConnections")
          ? getters.networkConnections.reduce(counter, 0)
          : 0,
        external: _.get(getters, "partnerConnections")
          ? getters.partnerConnections.reduce(counter, 0)
          : 0,
        providers: _.get(getters, "dataConnections")
          ? getters.dataConnections.reduce(integrationCounter, 0)
          : 0,
        integrations: _.get(getters, "integrationConnections")
          ? getters.integrationConnections.reduce(integrationCounter, 0)
          : 0,
      },
      inbound: {
        internal: countBySubscriptionType(
          _.get(getters, "networkConnections", []),
          [SubscriptionTypes.READER_AND_PUBLISHER, SubscriptionTypes.PUBLISHER]
        ),
        external: countBySubscriptionType(
          _.get(getters, "partnerConnections", []),
          [SubscriptionTypes.READER_AND_PUBLISHER, SubscriptionTypes.PUBLISHER]
        ),
      },
      outbound: {
        internal: countBySubscriptionType(
          _.get(getters, "networkConnections", []),
          [SubscriptionTypes.READER_AND_PUBLISHER, SubscriptionTypes.READER]
        ),
        external: countBySubscriptionType(
          _.get(getters, "partnerConnections", []),
          [SubscriptionTypes.READER_AND_PUBLISHER, SubscriptionTypes.READER]
        ),
      },
    }
  },
  transitionFilter: (state) => {
    return state.transitionFilter
  },
  queryObj: (state, getters, rootState, rootGetters) => {
    if (_.isEmpty(rootGetters["auth/activeGroup"])) {
      return
    }

    const groupNamespace = `g${rootGetters["auth/activeGroup"].id}`

    return {
      indices: [{ i: `${groupNamespace}_connections` }],
      filters: [{ a: "access_policy", t: "terms", v: [`${groupNamespace}:0`] }],
      sorts: [{ a: "name.raw", o: "asc" }],
      includes: [
        { a: "name" },
        { a: "total_companies" },
        { a: "total_fields" },
        { a: "connected_to" },
        { a: "enabled" },
        { a: "latest_activity" },
        { a: "id" },
        { a: "logo" },
        { a: "email" },
        { a: "marketing_connection_profile" },
        { a: "model_type" },
        { a: "network_name" },
        { a: "network_id" },
        { a: "use_case" },
        { a: "subscription_type" },
        { a: "solution_instance_id" },
        { a: "provider" },
        { a: "parent_id" },
        { a: "parent_type" },
        { a: "parent_name" },
        { a: "parent_logo" },
        { a: "authenticated" },
        { a: "configured " },
        { a: "word" },
        { a: "integration_parent" },
        { a: "branding_color" },
        { a: "integration_form_id" },
        { a: "external_form_path" },
        { a: "external_id" },
        { a: "is_active" },
        { a: "data_source" },
        { a: "status" },
        { a: "api_name" },
      ],
      custom_attrs: [{ pattern: "inject_connection_stats" }],
      keywords: [],
      meta: {
        admin: false,
        user_admin: false,
        split_response: false,
        page: 1,
        per_page: 1000,
        find_company: false,
      },
    }
  },
}

export const actions = {
  ...BaseActions,
  initConnections: ({ dispatch, commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      if (_.isEmpty(rootGetters["auth/activeGroup"])) {
        resolve()
        return
      }

      // Try to load from cached data, or fetch connections and go to index.
      dispatch("fetchConnections", payload)
        .then((c) => {
          resolve(c)
        })
        .finally(() => {
          commit("INCREMENT_RELOAD_KEY")
        })
    })
  },
  fetchConnections: ({ dispatch, commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      if (
        _.isEmpty(rootGetters["auth/activeGroup"]) ||
        _.isEmpty(getters["queryObj"])
      ) {
        return resolve()
      }

      dispatch(
        "setActiveFilter",
        _.get(payload, "active_filter") || _.first(getters["filters"])
      )

      dispatch(
        "setActiveScope",
        _.get(payload, "active_scope") || _.first(getters["scopes"])
      )

      if (
        !getters["loading"] &&
        (_.isEmpty(getters["connections"]) || _.get(payload, "reload", false))
      ) {
        commit("SET_LOADING", true)
        commit("RESET_CACHES")
        axios
          .get(`search?q=${JSON.stringify(getters["queryObj"])}`)
          .then((response) => {
            const connections = Object.freeze(_.get(response, "data.results"))
            let activeGroupData =
              _.find(connections, {
                use_case: ConnectionUseCase.GROUP_ACTIVE,
              }) || _.first(connections)

            if (!StaticHelper.blank(activeGroupData)) {
              activeGroupData.all = true
            } else {
              activeGroupData = { all: true }
              console.info("No active group connections found...")
            }
            dispatch("setConnections", connections)
            dispatch("setConnectionsCache", connections)
            dispatch(
              "setActiveConnection",
              _.get(payload, "active_connection") || activeGroupData
            )
            resolve(connections)
          })
          .catch((e) => {
            console.error(e)
            reject(e)
          })
          .finally(() => {
            commit("SET_LOADING", false)
          })
      } else {
        const connections = getters["connections"]
        dispatch(
          "setActiveConnection",
          _.get(payload, "active_connection") ||
            _.find(connections, "all") ||
            _.first(connections)
        )
        resolve(connections)
      }
    })
  },
  resetConnections: ({ dispatch, commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      if (
        _.isEmpty(rootGetters["auth/activeGroup"]) ||
        _.isEmpty(getters["queryObj"])
      ) {
        return resolve()
      }

      commit("SET_LOADING", true)
      axios
        .get(`search?q=${JSON.stringify(getters["queryObj"])}`)
        .then((response) => {
          const connections = Object.freeze(_.get(response, "data.results"))
          let activeGroupData =
            _.find(connections, {
              use_case: ConnectionUseCase.GROUP_ACTIVE,
            }) || _.first(connections)
          if (!StaticHelper.blank(activeGroupData)) {
            activeGroupData.all = true
          } else {
            activeGroupData = { all: true }
            console.info("No active group connections found...")
          }
          dispatch("setConnections", connections)
          dispatch("setConnectionsCache", connections)
          dispatch(
            "setActiveConnection",
            _.get(payload, "activeConnection") || activeGroupData
          )
          resolve(connections)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  pushWordsCache: ({ commit, dispatch }, word) => {
    commit("PUSH_WORDS_CACHE", word)
  },
  fetchConnectionsFromWords: _.debounce(
    ({ dispatch, commit, getters, rootGetters }) => {
      const words = getters["wordsCache"]
      if (_.isEmpty(words)) return

      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true)
        let queryObj = _.cloneDeep(getters["queryObj"])
        queryObj.filters.push({ a: "word", t: "terms", v: words })
        return axios
          .get(`search?q=${JSON.stringify(queryObj)}`)
          .then((response) => {
            const connections = Object.freeze(_.get(response, "data.results"))
            if (_.isEmpty(connections)) {
              resolve()
            } else {
              dispatch("setConnectionsCache", connections).then(() => {
                resolve()
              })
            }
          })
          .catch((e) => {
            console.error(e)
            reject(e)
          })
          .finally(() => {
            commit("RESET_WORDS_CACHE")
            commit("SET_LOADING", false)
          })
      })
    },
    500
  ),

  fetchConnectionCache: ({ dispatch, commit, getters }, word) => {
    if (_.isEmpty(word)) return
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      let queryObj = _.cloneDeep(getters["queryObj"])
      queryObj.filters.push({ a: "word", t: "term", v: word })
      return axios
        .get(`search?q=${JSON.stringify(queryObj)}`)
        .then((response) => {
          const connections = Object.freeze(_.get(response, "data.results"))
          dispatch("setConnectionsCache", connections).then(() => {
            resolve()
          })
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  resetCaches: ({ commit }) => {
    commit("RESET_CACHES")
  },
  setActiveConnection: (
    { commit, dispatch, getters, rootState, rootGetters },
    connection
  ) => {
    const f = getters["transitionFilter"]
      ? getters["transitionFilter"]
      : _.first(getters["filters"])
    dispatch("setActiveFilter", f)
    commit("SET_ACTIVE_CONNECTION", connection)
    commit("INCREMENT_RELOAD_KEY")
  },
  setConnections: ({ commit }, connections) => {
    commit("SET_CONNECTIONS", connections)
  },
  setConnectionsCache: ({ commit }, connections) => {
    return new Promise((resolve) => {
      if (!_.isEmpty(connections)) {
        connections.forEach((connection, i) => {
          commit("SET_CONNECTIONS_CACHE", connection)
        })
      } else {
        console.error("Connections - Cannot setConnectionsCache null")
      }
      resolve()
    })
  },
  setActiveFilter: ({ commit }, filter) => {
    commit("SET_ACTIVE_FILTER", filter)
    commit("INCREMENT_RELOAD_KEY")
  },
  setTransitionFilter: ({ commit }, filter) => {
    commit("SET_TRANSITION_FILTER", filter)
  },
  setActiveScope: ({ commit }, scope) => {
    commit("SET_ACTIVE_SCOPE", scope)
    commit("INCREMENT_RELOAD_KEY")
  },
  setSearchTerm: ({ commit }, searchTerm) => {
    commit("SET_SEARCH_TERM", searchTerm)
  },
  setConnectionSheetSearchTerm: ({ commit }, searchTerm) => {
    commit("SET_CONNECTION_SHEET_SEARCH_TERM", searchTerm)
  },
  getConnection: ({ commit, getters, dispatch }, payload) => {
    return new Promise((resolve) => {
      const findConnection = (allConnections, match) => {
        return match
          ? _.find(allConnections, {
              id: match.group_id || match.id,
              model_type: match.model_type || "Group",
              use_case: match.use_case,
            })
          : _.find(allConnections, "all")
      }

      if (_.isEmpty(getters.connections)) {
        commit("RESET_CACHES")
        dispatch("fetchConnections").then((connections) => {
          resolve(findConnection(connections, payload.connection))
        })
      } else {
        resolve(findConnection(getters.connections, payload.connection))
      }
    })
  },
  handleTransitionTo: ({ dispatch, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      if (rootGetters["auth/shareToken"]) {
        return resolve()
      }
      let connection = _.get(payload, "connection")
      let connectionWord =
        _.get(connection, "word") || _.get(connection, "id") || "all"

      // Something weird can happen sometimes where the above code sends us a variable which is not a string
      // Thus the code below ends up trying to use the "match" function against something that doesn't have it.
      // To solve this we're using introspection to determine if we've set the variable correctly.
      if (
        typeof connectionWord != "string" ||
        typeof connectionWord.match != "function"
      ) {
        connectionWord = "all" // Fall back to sending user to base of connections in error state.
      } else if (connectionWord.match(/^slf[0-9]+$/)) {
        connectionWord = "slf"
      } else if (connectionWord.match(/^msd365[0-9]+$/)) {
        connectionWord = "msd365"
      } else if (connectionWord.match(/^afi[0-9]+$/)) {
        connectionWord = "afi"
      } else if (connectionWord.match(/^if[0-9]+$/)) {
        connectionWord = "if"
      }

      // Company Imports (ie. Flatfile Import Records) should open the
      // import dialog and no longer be framed as a connection.
      if (connectionWord === "ir") {
        // Scope to only groups who are SRM enabled, otherwise resolve.
        if (_.get(rootGetters["auth/activeGroup"], "srm_enabled", false)) {
          dispatch(
            "app/setActiveDialog",
            {
              title: "Import",
              dialog: "activeModal",
              context: "ImportWrapper",
            },
            { root: true }
          )
        }
        return resolve()
      }

      router
        .push({
          name: "connection",
          params: {
            id: connectionWord,
            query: {
              active_connection: connection,
            },
          },
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  requestNewConnection: ({ dispatch, rootGetters }, connectionName) => {
    if (!_.isEmpty(rootGetters["auth/shareToken"])) {
      return window.open(
        "mailto:support@nucla.com?subject=Request information on Nucla&body=Hello Nucla Team, I'm interested in learning more about the platform and its integrations. Can you please share more information?",
        "_blank",
        "noopener noreferrer"
      )
    }

    const activeUserName = _.get(rootGetters["auth/activeUser"], "name")
    const activeGroupName = _.get(rootGetters["auth/activeGroup"], "name")
    const activeNetworkName = _.get(rootGetters["auth/activeNetwork"], "name")

    let path = `mailto:support@nucla.com?subject=Request to integrate ${
      connectionName || "connection"
    }&body=Hello Nucla Team, `

    if (activeUserName && activeGroupName && activeNetworkName) {
      path += `I'm ${activeUserName} from the ${activeGroupName} group in the ${activeNetworkName} network. `
    }

    path += `I'm interested in integrating ${
      connectionName || "a new connection"
    }. Can you please tell me more? `

    window.open(path, "_blank", "noopener noreferrer")
  },
  requestUpdateTwitterHandle: ({ dispatch }, company) => {
    let path = `mailto:support@nucla.com?subject=Request to update ${
      company.name || "X"
    }&body=Hello Nucla Team, %0D%0A%0D%0A`

    path += `Can you please update the X connection for ${company.name} (${company.url})?`

    window.open(path, "_blank", "noopener noreferrer")
  },
  transitionFromWord: (
    { dispatch, getters, rootGetters },
    sourceWordObject
  ) => {
    const word = _.get(sourceWordObject, "word")
    if (!word) {
      return
    }

    // Check for connection in cache. If it doesn't exist,
    // reload the connectionsCache before transition.
    if (
      word !== "tracked-companies" &&
      !_.find(getters.connections, { word: word })
    ) {
      dispatch("initConnections", { reload: true })
    }

    // ===
    // Table filter transitions
    // ===
    if (/^ir[0-9]+$/i.test(word) || word === "tracked-companies") {
      // Reset saved filters
      dispatch(
        "savedFilters/setAppliedSavedFilter",
        { appContext: AppContexts.GC_COMPANIES, savedFilter: {} },
        { root: true }
      )

      // Close dialogs before transition
      dispatch(
        "app/setActiveDialog",
        { dialog: "activeFullscreen" },
        { root: true }
      )
      dispatch("app/setActiveDialog", { dialog: "activeModal" }, { root: true })

      // Handle transition
      dispatch(
        "contextViews/handleTableFilterTransition",
        {
          parent_type: "Group",
          parent_id: _.get(rootGetters["auth/activeGroup"], "id"),
          route_object: {
            name: "group-view",
            query: {
              q: JSON.stringify({
                page: 1,
                per_page: 1000,
                f: {
                  s: { word: _.get(sourceWordObject, "word") },
                },
              }),
            },
          },
        },
        { root: true }
      )
    }

    // ===
    // Container show page transition
    // ===
    else {
      return router
        .push({
          name: "containers-show",
          params: { id: word },
        })
        .catch((e) => {})
    }
  },
  pushNewConnection({ commit }, payload) {
    commit("PUSH_NEW_CONNECTION", payload)
  },
}

export const mutations = {
  ...BaseMutations,
  RESET_CACHES: (state) => {
    state.connections = null
    state.searchTerm = null
    state.activeConnection = null
    state.activeFilter = null
    state.activeScope = null
    state.scope = ["All", "Shared", "Received"]
    ;(state.filters = [
      {
        name: "Activity",
        attr: "all",
      },
      {
        name: "Companies",
        attr: "company",
      },
      {
        name: "Reports",
        attr: "report",
      },
      { name: "Other", attr: ["web_link", "attachment"] },
    ]),
      (state.transitionFilter = null)
  },
  SET_CONNECTIONS: (state, connections) => {
    state.connections = connections
  },
  PUSH_WORDS_CACHE: (state, word) => {
    if (word && !state.wordsCache.includes(word)) {
      state.wordsCache.push(word)
    }
  },
  PUSH_NEW_CONNECTION: (state, connection) => {
    if (!connection || !connection.word) return
    state.connections = [...state.connections, connection]
    state.connectionsCache = {
      ...state.connectionsCache,
      [connection.word]: connection,
    }
  },
  RESET_WORDS_CACHE: (state) => {
    state.wordsCache = []
  },
  SET_CONNECTIONS_CACHE: (state, connection) => {
    Vue.set(state.connectionsCache, connection.word, connection)
  },
  SET_ACTIVE_CONNECTION: (state, connection) => {
    state.activeConnection = connection
  },
  SET_ACTIVE_FILTER: (state, filter) => {
    state.activeFilter = filter
  },
  SET_TRANSITION_FILTER: (state, filter) => {
    state.transitionFilter = filter
  },
  SET_SEARCH_TERM: (state, term) => {
    state.searchTerm = term
  },
  SET_CONNECTION_SHEET_SEARCH_TERM: (state, term) => {
    state.connectionSheetSearchTerm = term
  },
  SET_ACTIVE_SCOPE: (state, scope) => {
    state.activeScope = scope
  },
}
