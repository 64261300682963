/* eslint-disable */
import axios from "axios"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import staticHelpers from "@/src/utils/mixins/static-helpers"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
  performCustomAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)

      let attachments = payload.attachments
      let customActionPayload = {
        custom_action: payload.custom_action,
        source_lookup: payload.source_lookup,
      }

      let formData = new FormData()
      formData.append(
        "custom_action_query",
        JSON.stringify(customActionPayload)
      )
      if (!staticHelpers.blank(attachments)) {
        for (var i = 0; i < attachments.length; i++) {
          if (attachments[i].field_response_cuid) {
            formData.append(
              "attachments[][field_response_cuid]",
              attachments[i].field_response_cuid
            )
          }
          if (attachments[i].response_cuid) {
            formData.append(
              "attachments[][response_cuid]",
              attachments[i].response_cuid
            )
          }
          formData.append("attachments[][field_id]", attachments[i].field_id)
          formData.append(
            "attachments[][file]",
            attachments[i].file,
            attachments[i].file.name
          )
        }
      }

      axios
        .post("custom_actions", formData)
        .then((res) => {
          // Cache successful responses
          commit("INCREMENT_RELOAD_KEY")
          resolve(res)
        })
        .catch((e) => {
          // commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
}
