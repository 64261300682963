// ===
// Company Drawer Module handles store logic for the drawer view of the company profile;
// ===

import {
  CompanyProfileActions,
  CompanyProfileGetters,
  CompanyProfileMutations,
  CompanyProfileState,
} from "@utils/mixins/store/company-profile"
import {
  TableActions,
  TableGetters,
  TableMutations,
  TableState,
} from "@utils/mixins/store/table-state"

export const state = {
  ...CompanyProfileState,
  ...TableState,
}

export const getters = {
  ...CompanyProfileGetters,
  ...TableGetters,
}

export const actions = {
  ...CompanyProfileActions,
  ...TableActions,
  initAction: ({ dispatch, rootGetters, getters }, meta) => {
    if (_.get(meta, "report")) {
      dispatch("set", {
        k: "report",
        v: meta.report,
      })
    }
    if (_.get(meta, "company")) {
      dispatch("set", {
        k: "company",
        v: meta.company,
      })
    }
    if (_.isNumber(_.get(meta, "tableItemIndex"))) {
      dispatch("set", {
        k: "tableItemIndex",
        v: meta.tableItemIndex,
      })
    }

    // Check user's default behavior, swap to sheet layout as needed (0 = inactive, 1 = active),
    // and falls back to local state if the defaults are not loaded.
    if (
      _.isEmpty(rootGetters["auth/shareToken"]) &&
      _.get(rootGetters["auth/defaultBehavior"], "company_drawer") === 0 &&
      _.isEmpty(rootGetters["companySheet/company"])
    ) {
      dispatch("swapDefaultLayout")
    } else if (
      !rootGetters["app/activeDrawer"] ||
      rootGetters["app/activeDrawer"] !== "CompanyDrawerWrapper"
    ) {
      dispatch("app/setActiveDrawer", "CompanyDrawerWrapper", { root: true })
    }
  },
  swapDefaultLayout: ({ dispatch, commit }) => {
    dispatch("companySheet/setState", _.cloneDeep(state), { root: true })
    dispatch("auth/setDefaultBehavior", { company_drawer: 0 }, { root: true })
    commit("RESET_STATE")
  },
  setState: ({ dispatch, commit, rootGetters }, newState) => {
    let newStateClone = _.cloneDeep(newState)
    // Company Drawer only supports table card, which is activeSectionContext.index === 0.
    // this logic ensures the correct section context is loaded. If we want to expand
    // the company card to support the different sections on a company profile,
    // we'll need to update this logic.
    newStateClone.activeSectionContext = state.activeSectionContext
    commit("SET_STATE", newStateClone)
    if (
      !rootGetters["app/activeDrawer"] ||
      rootGetters["app/activeDrawer"] !== "CompanyDrawerWrapper"
    ) {
      dispatch("app/setActiveDrawer", "CompanyDrawerWrapper", { root: true })
    }
  },
}

export const mutations = {
  ...CompanyProfileMutations,
  ...TableMutations,
  RESET_STATE(state) {
    Object.assign(state, {
      company: null,
      report: null,
      tableItems: null,
      tableItemIndex: 0,
      tableMeta: {},
      activeSectionContext: {
        index: 0,
        search: null,
        isEditing: false,
      },
      skipSithReload: false,
    })
  },
}
