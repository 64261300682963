// ===
// Company Sheet Module handles store logic for the bottom sheet view of the company profile;
// ===

import {
  CompanyProfileActions,
  CompanyProfileGetters,
  CompanyProfileMutations,
  CompanyProfileState,
} from "@utils/mixins/store/company-profile"
import {
  TableActions,
  TableGetters,
  TableMutations,
  TableState,
} from "@utils/mixins/store/table-state"

export const state = {
  ...CompanyProfileState,
  ...TableState,
}

export const getters = {
  ...CompanyProfileGetters,
  ...TableGetters,
}

export const actions = {
  ...CompanyProfileActions,
  ...TableActions,
  initAction: ({ dispatch }, meta) => {
    if (_.get(meta, "company")) {
      dispatch("set", {
        k: "company",
        v: meta.company,
      })
    }
    if (_.isNumber(_.get(meta, "tableItemIndex"))) {
      dispatch("set", {
        k: "tableItemIndex",
        v: meta.tableItemIndex,
      })
    }
  },
  swapDefaultLayout: ({ dispatch, commit }) => {
    dispatch("companyDrawer/setState", state, { root: true })
    dispatch("auth/setDefaultBehavior", { company_drawer: 1 }, { root: true })
    commit("RESET_STATE")
  },
  setState: ({ commit }, newState) => {
    commit("SET_STATE", newState)
  },
}

export const mutations = {
  ...CompanyProfileMutations,
  ...TableMutations,
  RESET_STATE(state) {
    Object.assign(state, {
      company: null,
      report: null,
      tableItems: null,
      tableItemIndex: 0,
      tableMeta: {},
      activeSectionContext: {
        index: 0,
        search: null,
        isEditing: false,
      },
      skipSithReload: false,
    })
  },
}
