import axios from "axios"
import StaticHelper from "@mixins/static-helpers"
import { DataSources, DataSourcesHumanReadable } from "@mixins/data-sources"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  reloadCompanyList: false,
  sharedData: [],
  loadingSharedData: false,
}

export const getters = {
  ...BaseGetters,
  reloadCompanyList: (state) => {
    return state.reloadCompanyList
  },
  sharedData: (state) => {
    return state.sharedData
  },
  loadingSharedData: (state) => {
    return state.loadingSharedData
  },
}

export const actions = {
  ...BaseActions,
  fetchCompany: ({ commit, getters }, { id }) => {
    // Check to see if we've already cached the company
    let company = getters.findById({ id: id })

    if (company) {
      commit("SET_MODEL", company)
      return company
    }

    // Fetch company from API if there's no cache for company
    return axios.get(`/companies/${id}`).then((response) => {
      company = response.data.company
      commit("SET_MODEL", company)
      return company
    })
  },
  setMergeCompany: ({ commit, getters }, company) => {
    let cachedCompany = getters.findById({ id: company.id })
    commit("SET_MODEL", { ...(cachedCompany || {}), ...(company || {}) })
  },
  fetchSharedData: ({ commit }, payload) => {
    commit("TOGGLE_LOADING_SHARED_DATA")
    const params = {}
    params.company_id = payload.companyId
    params.post_ids = StaticHelper.blank(payload.postIds)
      ? null
      : payload.postIds
    params.report_ids = StaticHelper.blank(payload.reportIds)
      ? null
      : payload.reportIds

    return axios
      .get("snowflakes/shared_company_data", { params: params })
      .then((response) => {
        commit("SET_SHARED_DATA", response.data.shared_data)
      })
      .catch((err) => {
        commit("SET_SHARED_DATA", [])
        console.error("Error loading shared data", err)
      })
      .finally(() => {
        commit("TOGGLE_LOADING_SHARED_DATA")
      })
  },
  reloadCompanyList: ({ commit }) => {
    commit("TOGGLE_RELOAD_COMPANY_LIST")
  },
  toggleLoadingSharedData: ({ commit }) => {
    commit("TOGGLE_LOADING_SHARED_DATA")
  },
  createCompany: ({ dispatch }, { company, source }) => {
    return new Promise((resolve, reject) => {
      // Platform results use the Company model ID
      // Clearbit results use the domain as the ID
      // Crunchbase results use the CB permalink as the ID
      let companyId = company.id || company.domain

      if (source === DataSources.CRUNCHBASE) {
        companyId = company.cb_permalink
      }

      axios
        .post("snowflakes/create_company", {
          id: companyId,
          source: source || DataSources.KITE,
        })
        .then((resp) => {
          let c = _.get(resp, "data.company")

          dispatch(
            "amplitude/logEvent",
            {
              name: "Create Company",
              properties: {
                id: _.get(c, "id"),
                name: _.get(c, "name"),
                source: source,
              },
            },
            { root: true }
          )
          resolve(c)
        })
        .catch((e) => {
          console.error(e)
          dispatch("catchCompanyCreateError", source)
          reject(e)
        })
    })
  },
  addToGroup: ({ dispatch }, { company, source }) => {
    return new Promise((resolve, reject) => {
      // Platform results use the company model ID
      // Clearbit results use the domain as the ID
      // Crunchbase results use the CB permalink as the ID
      let companyId = company.id || company.domain

      if (source === DataSources.CRUNCHBASE) {
        companyId = company.cb_permalink
      }

      axios
        .post("snowflakes/add_company", {
          id: companyId,
          source: source || DataSources.KITE,
        })
        .then((resp) => {
          let c = _.get(resp, "data.company")

          dispatch(
            "amplitude/logEvent",
            {
              name: "Add Company",
              properties: {
                id: _.get(c, "id"),
                name: _.get(c, "name"),
                source: source,
              },
            },
            { root: true }
          )
          resolve(c)
        })
        .catch((e) => {
          console.error(e)
          dispatch("catchCompanyCreateError", source)
          reject(e)
        })
    })
  },
  catchCompanyCreateError: ({ dispatch }, source) => {
    const sourceName =
      source === DataSources.KITE ? "Nucla" : DataSourcesHumanReadable[source]
    dispatch(
      "app/setSnackbar",
      {
        text: `An issue occured while trying to import a company from ${sourceName}.`,
        icon: "warning",
        iconColor: "#DE7974",
      },
      {
        root: true,
      }
    )
  },
}

export const mutations = {
  ...BaseMutations,
  SET_SHARED_DATA(state, data) {
    state.sharedData = data
  },
  TOGGLE_LOADING_SHARED_DATA(state) {
    state.loadingSharedData = !state.loadingSharedData
  },
  TOGGLE_RELOAD_COMPANY_LIST(state) {
    state.reloadCompanyList = !state.reloadCompanyList
  },
}
