import axios from "axios"

export const state = {
  activeCompany: null,
  propDataReloadKey: 0,
  fieldCardGridReloadKey: 0,
  companyContacts: [],
}

export const getters = {
  activeCompany: (state) => {
    return state.activeCompany
  },
  propDataReloadKey: (state) => {
    return state.propDataReloadKey
  },
  fieldCardGridReloadKey: (state) => {
    return state.fieldCardGridReloadKey
  },
  companyContacts: (state) => {
    return state.companyContacts
  },
}

export const actions = {
  setActiveCompany: ({ commit }, company) => {
    commit("SET_ACTIVE_COMPANY", company)
  },
  incrementPropDataReloadKey: ({ commit }) => {
    commit("INCREMENT_PROP_DATA_RELOAD_KEY")
  },
  incrementFieldCardGridReloadKey: ({ commit }) => {
    commit("INCREMENT_FIELD_CARD_GRID_RELOAD_KEY")
  },
  setCompanyContacts: ({ commit }, fields) => {
    commit("SET_COMPANY_CONTACTS", fields)
  },
  findCompanyContacts: ({ commit }, payload) => {
    commit("SET_COMPANY_CONTACTS", [])
    return new Promise((resolve, reject) => {
      axios
        .get(`snowflakes/company_contacts`, { params: payload })
        .then((resp) => {
          commit("SET_COMPANY_CONTACTS", resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export const mutations = {
  SET_ACTIVE_COMPANY(state, company) {
    state.activeCompany = company
  },
  INCREMENT_PROP_DATA_RELOAD_KEY(state) {
    state.propDataReloadKey++
  },
  INCREMENT_FIELD_CARD_GRID_RELOAD_KEY(state) {
    state.fieldCardGridReloadKey++
  },
  SET_COMPANY_CONTACTS(state, contactData) {
    state.companyContacts = contactData
  },
}
