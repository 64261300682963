import axios from "axios"
import router from "@router"
import { ContextViewStates } from "@mixins/app-contexts"
import Vue from "vue"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  views: {},
  initializing: [],
  appBarTabs: null,
  activeTabs: null,
  fileUrl: null,
}

export const getters = {
  ...BaseGetters,
  views: (state) => {
    return state.views
  },
  appBarTabs: (state) => {
    return state.appBarTabs
  },
  activeTabs: (state) => {
    return state.activeTabs
  },
  initializing: (state) => {
    return state.initializing
  },
  fileUrl: (state) => {
    return state.fileUrl
  },
}

export const actions = {
  ...BaseActions,
  getRouteByPermalinkFor: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      dispatch("getRoutesFor", payload)
        .then((routes) => {
          let defaultRoute = _.find(routes, (r) => {
            return _.isEqual(_.get(r, "params.permalink"), payload.permalink)
          })

          if (_.isEmpty(defaultRoute)) {
            // Fallback lookup
            if (payload.permalink_fallback) {
              defaultRoute = _.find(routes, (r) => {
                return _.isEqual(
                  _.get(r, "params.permalink"),
                  payload.permalink_fallback
                )
              })
            }
            // Default
            if (_.isEmpty(defaultRoute)) {
              defaultRoute = _.first(routes)
            }
          }

          resolve(defaultRoute)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getRoutesFor: ({ commit, dispatch, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      const shareToken = rootGetters["auth/shareToken"]

      dispatch("getViewsFor", payload)
        .then((views) => {
          const routes = _.map(views, (v) => {
            return {
              name: shareToken
                ? `share-${_.kebabCase(v.parent_type)}-view`
                : `${_.kebabCase(v.parent_type)}-view`,
              title: v.name,
              icon: v.icon,
              params: {
                id: v.parent_id,
                permalink: v.permalink,
                context_view: v,
                secret: shareToken,
              },
              context_view_id: v.id,
            }
          })

          if (_.isEqual("Group", payload.parent_type)) {
            commit("SET_APP_BAR_TABS", routes)
          } else {
            commit("SET_ACTIVE_TABS", routes)
          }

          resolve(routes)
        })
        .catch((e) => {
          if (_.isEqual("Group", payload.parent_type)) {
            commit("SET_APP_BAR_TABS", null)
          } else {
            commit("SET_ACTIVE_TABS", null)
          }
          reject(e)
        })
    })
  },
  getViewByPermalinkFor: ({ getters, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      dispatch("getViewsFor", payload)
        .then((views) => {
          let viewCache = _.find(views, (v) => {
            return _.isEqual(_.get(v, "permalink"), payload.permalink)
          })

          // Fallback
          if (_.isEmpty(viewCache)) {
            viewCache = _.first(views)
          }

          resolve(viewCache)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getViewsFor: ({ getters, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      const cache = _.get(
        getters["views"],
        `${_.snakeCase(payload.parent_type)}.${payload.parent_id}`
      )

      if (!_.isEmpty(cache) && !_.get(payload, "reset_cache")) {
        resolve(cache)
      } else {
        dispatch("setViewsFor", payload)
          .then((views) => {
            resolve(views)
          })
          .catch((e) => {
            reject(e)
          })
      }
    })
  },
  setViewsFor: ({ commit, dispatch, getters, rootGetters }, payload) => {
    if (!_.get(payload, "parent_id")) return
    const parentNamespace = `${_.snakeCase(payload.parent_type)}.${
      payload.parent_id
    }`

    // If already initializing, return cached view or wait.
    if (getters.initializing.includes(parentNamespace)) {
      return _.get(getters.views, parentNamespace, null)
    }

    // Track parentNamespaces as they initialize to prevent race conditions.
    commit("PUSH_INITIALIZING", parentNamespace)

    return new Promise((resolve, reject) => {
      axios
        .get("snowflakes/context_views_for", {
          params: {
            [`${_.snakeCase(payload.parent_type)}_id`]: payload.parent_id,
          },
        })
        .then((resp) => {
          let views = _.get(resp, "data.context_views", [])

          // If parent_type is Group, check to make sure the activeGroup is SRM enabled.
          // ECO-only group views should filter to only Connections, Table, Reports, Team tabs. Because the setting
          // can be toggled on/off on the group, data will persist and reappear if reverted.
          if (
            _.isEqual(payload.parent_type, "Group") &&
            !_.get(rootGetters["auth/activeGroup"], "srm_enabled")
          ) {
            views = _.filter(views, (v) => {
              return [
                ContextViewStates.ACTIVITY,
                ContextViewStates.TABLE,
                ContextViewStates.REPORTS,
                ContextViewStates.HOME,
                ContextViewStates.GROUPS,
              ].includes(v.context_type)
            })
          }

          commit("SET_VIEWS_FOR", {
            views: views,
            parent_id: payload.parent_id,
            parent_type: payload.parent_type,
          })
          resolve(views)
        })
        .catch((e) => {
          reject(e)
        })

      // Complete initialization process for parentNamespace,
      // throttle at 3s to prevent the same data from
      // being unnecessarily reloaded.
      setTimeout(() => {
        commit("POP_INITIALIZING", parentNamespace)
      }, 3000)
    })
  },
  updateViewsFor: ({ commit, dispatch, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post("snowflakes/update_context_views", payload.params)
        .then((resp) => {
          const shareToken = rootGetters["auth/shareToken"]
          const views = _.get(resp, "data.context_views", [])
          const routes = _.map(views, (v) => {
            return {
              name: shareToken
                ? `share-${_.kebabCase(v.parent_type)}-view`
                : `${_.kebabCase(v.parent_type)}-view`,
              title: v.name,
              icon: v.icon,
              params: {
                id: v.parent_id,
                permalink: v.permalink,
                context_view: v,
                secret: shareToken,
              },
            }
          })
          commit("SET_VIEWS_FOR", {
            parent_id: payload.parent_id,
            parent_type: payload.parent_type,
            views: views,
          })

          if (_.isEqual("Group", payload.parent_type)) {
            commit("SET_APP_BAR_TABS", routes)
          } else {
            commit("SET_ACTIVE_TABS", routes)
          }

          resolve(views)
        })
        .catch((e) => {
          if (_.isEqual("Group", payload.parent_type)) {
            commit("SET_APP_BAR_TABS", null)
          } else {
            commit("SET_ACTIVE_TABS", null)
          }
          reject(e)
        })
    })
  },
  updateView: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`context_views/${payload.id}`, payload.params)
        .then((resp) => {
          const view = _.get(resp, "data.context_view", [])
          commit("SET_VIEW_FOR", {
            parent_id: payload.parent_id,
            parent_type: payload.parent_type,
            view: view,
          })
          resolve(view)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  handleTableFilterTransition: ({ dispatch, rootGetters }, payload) => {
    // Helper to assist with transitions to filtered table views.
    // - receives base routeObject with filter query params
    // - sets contextView attrs on routeObject and transitions
    new Promise((resolve, reject) => {
      const routeObject = payload.route_object

      dispatch("getViewsFor", payload)
        .then((views) => {
          const tableView =
            _.find(views, {
              context_type: ContextViewStates.TABLE,
            }) || _.first(views)

          const baseRoute = {
            name: _.get(routeObject, "name"),
            params: {
              permalink: _.get(tableView, "permalink"),
              context_view: tableView,
              secret: rootGetters["auth/shareToken"],
              ...routeObject.params,
            },
            query: { q: null },
          }

          // Push clear query params, then set filter query
          router.push(baseRoute).finally(() => {
            _.set(baseRoute, "query", routeObject.query)
            router.replace(baseRoute)
            resolve()
          })
        })
        .catch((e) => {
          // Fallback transition
          router
            .push({
              name: _.get(routeObject, "name"),
              params: _.get(routeObject, "params"),
              query: { q: null },
            })
            .finally(() => {
              if (_.get(routeObject, "query")) {
                router.replace({ query: routeObject.query })
              }
              reject()
            })
        })
    })
  },
  handleContextViewTransition: ({ dispatch, rootGetters }, payload) => {
    // Helper to assist with transitions to feed view.
    new Promise((resolve, reject) => {
      dispatch("getViewsFor", payload)
        .then((views) => {
          const contextView =
            _.find(views, {
              context_type: payload.context_type,
            }) || _.first(views)

          const baseRoute = {
            name: "group-view",
            params: {
              permalink: _.get(contextView, "permalink"),
              context_view: contextView,
              secret: rootGetters["auth/shareToken"],
            },
            query: { q: null },
          }

          router.push(baseRoute).finally(() => {
            resolve()
          })
        })
        .catch((e) => {
          // Fallback transition
          router
            .push({
              name: "group-view",
              params: _.get(routeObject, "params"),
              query: { q: null },
            })
            .finally(() => {
              reject()
            })
        })
    })
  },
  handleReportContextViewTransition: (
    { dispatch, getters, rootGetters },
    payload
  ) => {
    // Helper to assist with transitions into reports
    new Promise((resolve, reject) => {
      dispatch("getRoutesFor", payload)
        .then(() => {
          let baseRoute = {
            name: "report-view",
            params: {
              id: payload.parent_id,
              permalink: _.get(
                getters.activeTabs,
                "[0].params.context_view.permalink"
              ),
            },
          }

          // Comments Drawer, add query param if included with payload
          if (payload.notes) {
            _.set(baseRoute, "query.notes", true)
          }

          router
            .push(baseRoute)
            .finally(() => {
              resolve()
            })
            .catch((e) => {})
        })
        .catch((e) => {
          // Fallback transition
          router
            .push({
              name: "report-view",
              params: {
                id: payload.parent_id,
              },
              query: { q: null },
            })
            .finally(() => {
              reject()
            })
        })
    })
  },
  transitionToContainersWithFilter: (
    { dispatch, getters, rootGetters },
    params
  ) => {
    const transitionWord = params.word
    const reportRouteContext =
      _.get(router.currentRoute, "params.context_view.context_type") ===
      ContextViewStates.REPORTS

    if (transitionWord) {
      dispatch("app/closeAllActiveDialogs", null, { root: true })
      const signal = {
        k: "setDataDropdownFromTransition",
        v: {
          word: transitionWord,
          runOnInit: !reportRouteContext,
        },
      }
      dispatch("signals/setSignal", signal, { root: true })
      if (!reportRouteContext) {
        dispatch("handleContextViewTransition", {
          parent_id: _.get(rootGetters["auth/activeGroup"], "id"),
          parent_type: "Group",
          context_type: ContextViewStates.REPORTS,
        })
      }
    }
  },
  setFileUrl: ({ commit }, fileUrl) => {
    // As of July 2023, this is only used for the "File Viewer"
    // tab time within a Report. If functionality extends to
    // allow multiple tabs or other tab parents, integrate
    // the cache reset logic so the data reflects correctly.
    commit("SET_FILE_URL", fileUrl)
  },
}

export const mutations = {
  ...BaseMutations,
  SET_VIEWS_FOR(state, payload) {
    Vue.set(
      state.views,
      `${_.snakeCase(payload.parent_type)}.${payload.parent_id}`,
      payload.views
    )
  },
  SET_VIEW_FOR(state, payload) {
    let viewsCache = _.cloneDeep(
      _.get(
        state.views,
        `${_.snakeCase(payload.parent_type)}.${payload.parent_id}`
      )
    )

    viewsCache = _.map(viewsCache, (v) => {
      if (_.isEqual(v.id, payload.view.id)) {
        v = payload.view
      }
      return v
    })

    Vue.set(
      state.views,
      `${_.snakeCase(payload.parent_type)}.${payload.parent_id}`,
      viewsCache
    )
  },
  SET_ACTIVE_TABS(state, tabs) {
    state.activeTabs = tabs
  },
  SET_APP_BAR_TABS(state, tabs) {
    state.appBarTabs = tabs
  },
  PUSH_INITIALIZING(state, parentNamespace) {
    state.initializing.push(parentNamespace)
  },
  POP_INITIALIZING(state, parentNamespace) {
    state.initializing = _.filter(
      state.initializing,
      (i) => !_.isEqual(i, parentNamespace)
    )
  },
  SET_FILE_URL(state, fileUrl) {
    state.fileUrl = fileUrl
  },
}
