// Special thanks to: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
let isIEBase = /*@cc_on!@*/ false || !!document.documentMode
let isChromeBase =
  !!window.chrome &&
  (!!window.chrome.webstore || !!window.chrome.runtime || !!window.chrome.app)
let isOperaBase =
  (!!window.opr && !!opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(" OPR/") >= 0

export const state = {
  isOpera: isOperaBase,
  isFirefox: typeof InstallTrigger !== "undefined",
  isSafari:
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    ),
  isIE: isIEBase,
  isEdge: !isIEBase && !!window.StyleMedia,
  isChrome: isChromeBase,
  isEdgeChromium: isChromeBase && navigator.userAgent.indexOf("Edg") != -1,
  isBlink: (isChromeBase || isOperaBase) && !!window.CSS,
}

export const getters = {
  activeBrowser: (state) => {
    if (state.isSafari) {
      return "safari"
    } else if (state.isEdgeChromium) {
      return "edge"
    } else if (state.isFirefox) {
      return "firefox"
    } else if (state.isChrome) {
      return "chrome"
    }
  },
  isOpera: (state) => {
    return state.isOpera
  },
  isFirefox: (state) => {
    return state.isFirefox
  },
  isSafari: (state) => {
    return state.isSafari
  },
  isIE: (state) => {
    return state.isIE
  },
  isEdge: (state) => {
    return state.isEdge
  },
  isChrome: (state) => {
    return state.isChrome
  },
  isEdgeChromium: (state) => {
    return state.isEdgeChromium
  },
  isBlink: (state) => {
    return state.isBlink
  },
  maxBrowserImageExport: (state) => {
    if (state.isChrome) {
      return 16384

      // While Firefox & Safari should be able to handle higher resolutions I've found the browser errors out at anything higher than this value
    } else if (state.isFirefox || state.isSafari) {
      return 8192

      // For all other browser cases we are just defaulting to a lower value so it will for sure render
    } else {
      return 4096 // IE
    }
  },
}

export const actions = {}
export const mutations = {}
