/* eslint-disable */
import axios from "axios"
import SearchMixin from "@mixins/search"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  companies: [],
}

export const getters = {
  ...BaseGetters,
  companies: (state) => {
    return state.companies
  },
}

export const actions = {
  ...BaseActions,
  fetchCompanies: ({ commit }, payload) => {
    axios
      .get("search", {
        params: {
          q: SearchMixin.buildSearchObject({
            indices: ["companies"],
            filters: [{ attr: "id", type: "terms", value: payload.ids }],
            sorts: [],
            includes: ["name", "logo_url"],
            page: 1,
            per_page: 50,
          }),
        },
      })
      .then((resp) => {
        commit("SET_COMPANIES", resp.data.results)
      })
      .catch((e) => {
        console.error(e)
      })
  },
}

export const mutations = {
  ...BaseMutations,
  SET_COMPANIES: (state, results) => {
    state.companies = _.uniq(_.concat(state.companies, results))
  },
}
