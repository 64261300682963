import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import axios from "axios"
import SearchMixin from "@mixins/search"
import CustomAttrs from "@mixins/custom-attributes"
import { FavoriteUseCase } from "@utils/mixins/favorites"
import { ContextViewStates } from "@mixins/app-contexts"
import router from "@router"

export const state = {
  ...BaseState,
  favorites: [],
  isSavingModel: false,
}

export const getters = {
  ...BaseGetters,
  isSavingModel: (state) => {
    return state.isSavingModel
  },
  groupPins: (state, getters, rootState, rootGetters) => {
    return _.filter(state.favorites, {
      use_case: FavoriteUseCase.GROUP,
      group_id: rootGetters["auth/activeGroup"].id,
    })
  },
  userFavorites: (state, getters, rootState, rootGetters) => {
    return _.filter(state.favorites, {
      use_case: FavoriteUseCase.USER,
      group_id: rootGetters["auth/activeGroup"].id,
      user_id: rootGetters["auth/activeUser"].id,
    })
  },
}

export const actions = {
  ...BaseActions,
  loadFavorites: ({ dispatch, commit, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      if (getters.loading || rootGetters["auth/shareToken"]) {
        return resolve()
      }

      commit("SET_LOADING", true)

      const searchObj = SearchMixin.buildSearchObject({
        indices: ["favorites"],
        includes: [
          "id",
          "created_at",
          "group_id",
          "user_id",
          "use_case",
          "fav_type",
          "report_id",
          "fav_order",
        ],
        sorts: [{ attr: "created_at", order: "asc" }],
        customAttrs: [CustomAttrs.favoritesRelationalData()],
        keywords: [],
        filters: [
          // Load favorites in the context of the active group AND that are:
          // - group-level favorites (use_case 0) OR
          // - user-level favorites (use_case 1) that match activeUser.id
          {
            attr: "use_case",
            type: "term",
            value: FavoriteUseCase.USER,
            verb: "must",
          },
          {
            attr: "user_id",
            type: "term",
            value: rootGetters["auth/activeUser"].id,
            verb: "must",
            nested: 1,
            nestedVerb: "should",
          },
          {
            attr: "use_case",
            type: "term",
            value: FavoriteUseCase.GROUP,
            verb: "must",
            nested: 1,
            nestedVerb: "should",
          },
          {
            nested: 2,
            nestedVerb: "must",
          },
          {
            attr: "group_id",
            type: "term",
            value: rootGetters["auth/activeGroup"].id,
            verb: "must",
          },
        ],
        page: 1,
        per_page: 1000,
      })

      axios
        .get("search", { params: { q: searchObj } })
        .then((res) => {
          commit("SET_FAVORITES", _.get(res, "data.results", []))
          resolve()
        })
        .catch((e) => {
          console.error(e)
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  createFavorite: ({ dispatch, commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      if (getters.isSavingModel || getters.loading) {
        // Prevent spamming the server if already saving a model
        return resolve()
      } else if (
        // Prevent non-admins from managing group-level favorites
        payload.use_case === FavoriteUseCase.GROUP &&
        !rootGetters["auth/userIsAdminOf"]({
          id: _.get(rootGetters["activeGroup"], "id"),
          namespace: "groups",
        })
      ) {
        return reject()
      } else {
        commit("SET_IS_SAVING_MODEL", true)
        axios
          .post("favorites", payload)
          .then(() => {
            dispatch("resetCachedData").then(() => {
              resolve()
            })
          })
          .catch((e) => {
            console.error(e)
            commit("SET_ERRORS", e)
            reject(e)
          })
          .finally(() => {
            commit("SET_IS_SAVING_MODEL", false)
          })
      }
    })
  },
  deleteFavorite: ({ dispatch, commit, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      if (getters.isSavingModel || getters.loading) {
        // Prevent spamming the server if already saving a model
        return resolve()
      } else if (
        // Prevent non-admins from managing group-level favorites
        (payload.use_case === FavoriteUseCase.GROUP &&
          !rootGetters["auth/userIsAdminOf"]({
            id: _.get(rootGetters["activeGroup"], "id"),
            namespace: "groups",
          })) ||
        // Prevent different users from deleting user-level favorites
        (payload.use_case === FavoriteUseCase.USER &&
          payload.user_id !== rootGetters["auth/activeUser"].id)
      ) {
        return reject()
      } else {
        commit("SET_IS_SAVING_MODEL", true)
        axios
          .delete(`favorites/${_.get(payload, "id")}`)
          .then(() => {
            dispatch("resetCachedData").then(() => {
              resolve()
            })
          })
          .catch((e) => {
            console.error(e)
            commit("SET_ERRORS", e)
            reject(e)
          })
          .finally(() => {
            commit("SET_IS_SAVING_MODEL", false)
          })
      }
    })
  },
  resetCachedData: ({ dispatch, commit, getters, rootGetters }) => {
    return new Promise((resolve) => {
      let currentRoute = router.currentRoute

      if (
        // Reports Index Table
        (_.get(currentRoute, "name", "") === "group-view" &&
          _.get(currentRoute, "params.context_view.context_type") ===
            ContextViewStates.REPORTS) ||
        // Reports Tab on Company Sheet
        _.get(rootGetters["companySheet/company"], "id")
      ) {
        dispatch("sithState/setLoading", true, { root: true })
        dispatch("app/reloadBaseSith", null, { root: true })
      }

      dispatch("loadFavorites")
      resolve()
    })
  },
}

export const mutations = {
  ...BaseMutations,
  SET_FAVORITES(state, payload) {
    state.favorites = payload
  },
  SET_IS_SAVING_MODEL(state, isSaving) {
    state.isSavingModel = isSaving
  },
}
