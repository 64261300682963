export const state = {}

export const getters = {
  isKite: (state) => {
    return window.location.hostname.match(/kitesrm\.com/i) !== null
  },
  isNucla: (state) => {
    return window.location.hostname.match(/nucla\.com/i) !== null
  },
  isStage: (state) => {
    return window.location.hostname.match(/stage\.app/i) !== null
  },
  isDev: (state) => {
    return window.location.hostname.match(/dev\.app/i) !== null
  },
  apiUrl: (state, getters) => {
    return `${getters.serverBaseUrl}api/v1`
  },
  serverBaseUrl: (state, getters) => {
    if (getters.isKite) {
      if(getters.isStage) return "https://stage.api.kitesrm.com/"
      if(getters.isDev) return "https://dev.api.kitesrm.com/"
      return "https://api.kitesrm.com/"
    } else if (getters.isNucla) {
      if(getters.isStage) return "https://stage.api.nucla.com/"
      if(getters.isDev) return "https://dev.api.nucla.com/"
      return "https://api.nucla.com/"
    } else {
      return process.env.VUE_APP_SERVER_BASE_URL
    }
  },
  clientBaseUrl: (state, getters) => {
    if (getters.isKite) {
      if(getters.isStage) return "https://stage.app.kitesrm.com/"
      if(getters.isDev) return "https://dev.app.kitesrm.com/"
      return "https://app.kitesrm.com/"
    } else if (getters.isNucla) {
      if(getters.isStage) return "https://stage.app.nucla.com/"
      if(getters.isDev) return "https://dev.app.nucla.com/"
      return "https://app.nucla.com/"
    } else {
      return process.env.VUE_APP_CLIENT_URL
    }
  }
}

export const actions = {}
export const mutations = {}
