import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  field: null,
  report: null,
  primaryFilter: null,
  valueFilters: [],
  removedFilters: [],
  isNewReport: false,
  isExistingReport: false,
  isGroupFilterFlow: false,
}

export const getters = {
  ...BaseGetters,
  field: (state) => {
    return state.field
  },
  report: (state) => {
    return state.report
  },
  valueFilters: (state) => {
    return state.valueFilters
  },
  removedFilters: (state) => {
    return state.removedFilters
  },
  primaryFilter: (state) => {
    return state.primaryFilter
  },
  isNewReport: (state) => {
    return state.isNewReport
  },
  isExistingReport: (state) => {
    return state.isExistingReport
  },
  isGroupFilterFlow: (state) => {
    return state.isGroupFilterFlow
  },
}

export const actions = {
  ...BaseActions,
  setField: ({ commit }, field) => {
    commit("SET_FIELD", field)
  },
  resetField: ({ commit }) => {
    commit("RESET_FIELD")
  },
  setValueFilters: ({ commit }, valueFilters) => {
    commit("SET_VALUE_FILTERS", valueFilters)
  },
  pushValueFilter: ({ commit }, valueFilter) => {
    commit("PUSH_VALUE_FILTER", valueFilter)
  },
  updateValueFilter: ({ commit }, { oldFilter, newFilter }) => {
    commit("UPDATE_VALUE_FILTER", { oldFilter, newFilter })
  },
  removeValueFilter: ({ commit }, valueFilter) => {
    commit("REMOVE_VALUE_FILTER", valueFilter)
  },
  resetValueFilters: ({ commit }) => {
    commit("RESET_VALUE_FILTERS")
  },
  setCreateReportFlow: ({ commit }) => {
    commit("SET_CREATE_REPORT_FLOW")
  },
  setExistingReportFlow: ({ commit }) => {
    commit("SET_EXISTING_REPORT_FLOW")
  },
  setGroupFilterFlow: ({ commit }) => {
    commit("SET_GROUP_FILTER_FLOW")
  },
  resetFlowState: ({ commit }) => {
    commit("RESET_FLOW_STATE")
  },
  setReport: ({ commit }, report) => {
    commit("SET_REPORT", report)
  },
  resetReport: ({ commit }) => {
    commit("RESET_REPORT")
  },
  setPrimaryFilter: ({ commit }, primaryFilter) => {
    commit("SET_PRIMARY_FILTER", primaryFilter)
  },
  resetPrimaryFilter: ({ commit }) => {
    commit("RESET_PRIMARY_FILTER")
  },
  downcaseIslandModels: ({ commit, state, dispatch }) => {
    const promises = []
    if (state.isNewReport && !!state.report) {
      const reportParams = {
        model: "Report",
        id: state.report.id,
      }
      promises.push(dispatch("deletePromise", reportParams))
    }
    if (state.primaryFilter) {
      const primaryFilterParams = {
        model: "SavedFilter",
        id: state.primaryFilter.id,
      }
      promises.push(dispatch("deletePromise", primaryFilterParams))
    }
    if (state.valueFilters.length) {
      const valueFilterParams = {
        model: "SavedFilter",
        selected: state.valueFilters,
      }
      promises.push(dispatch("deleteAllPromise", valueFilterParams))
    }
    Promise.all(promises)
      .then((values) => {
        commit("RESET_CACHES")
        return values
      })
      .catch((e) => {
        console.error(e)
      })
  },
  resetCaches: ({ commit }) => {
    commit("RESET_CACHES")
  },
}

export const mutations = {
  ...BaseMutations,
  SET_FIELD(state, field) {
    state.field = field
  },
  RESET_FIELD(state) {
    state.field = null
  },
  SET_REPORT(state, report) {
    state.report = report
  },
  RESET_REPORT(state) {
    state.report = null
  },
  SET_PRIMARY_FILTER(state, primaryFilter) {
    state.primaryFilter = primaryFilter
  },
  RESET_PRIMARY_FILTER(state) {
    state.primaryFilter = null
  },
  SET_VALUE_FILTERS(state, valueFilters) {
    state.valueFilters = valueFilters
  },
  RESET_VALUE_FILTERS(state) {
    state.valueFilters = []
    state.removedFilters = []
  },
  PUSH_VALUE_FILTER(state, filter) {
    state.valueFilters.push(filter)
  },
  UPDATE_VALUE_FILTER(state, { oldFilter, newFilter }) {
    const index = _.findIndex(state.valueFilters, oldFilter)
    if (oldFilter.id) {
      newFilter.id = oldFilter.id
    }
    state.valueFilters.splice(index, 1, newFilter)
  },
  REMOVE_VALUE_FILTER(state, filter) {
    const index = _.findIndex(state.valueFilters, filter)
    state.removedFilters.push(state.valueFilters[index])
    state.valueFilters.splice(index, 1)
  },
  SET_CREATE_REPORT_FLOW(state) {
    state.isNewReport = true
  },
  SET_EXISTING_REPORT_FLOW(state) {
    state.isExistingReport = true
  },
  SET_GROUP_FILTER_FLOW(state) {
    state.isGroupFilterFlow = true
  },
  RESET_FLOW_STATE(state) {
    state.isNewReport = false
    state.isExistingReport = false
  },
  RESET_CACHES(state) {
    state.field = null
    state.report = null
    state.primaryFilter = null
    state.valueFilters = []
    state.removedFilters = []
    state.isNewReport = false
    state.isExistingReport = false
    state.isGroupFilterFlow = false
  },
}
