import axios from "axios"
import StaticHelper from "@mixins/static-helpers"

export const state = {
  all: [],
  loading: false,
  prevGroupId: null,
}

export const getters = {
  all: (state) => {
    return state.all
  },
  loading: (state) => {
    return state.loading
  },
  prevGroupId: (state) => {
    return state.prevGroupId
  },
}

export const actions = {
  loadAll: ({ commit, state, getters }, group_id) => {
    if (
      group_id == getters.prevGroupId &&
      (!StaticHelper.blank(state.all) || state.loading)
    ) {
      return
    }
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("SET_PREV_GROUP_ID", group_id)
      axios
        .get("snowflakes/group_contacts", { params: { group_id: group_id } })
        .then((resp) => {
          const contacts = _.get(resp, "data.results") || []
          commit("SET_ALL", contacts)
          resolve(contacts)
        })
        .catch((e) => {
          reject(e)
          console.error(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  pushContact: ({ commit, state }, newContact) => {
    if (StaticHelper.blank(state.all)) {
      commit("SET_ALL", [newContact])
    } else {
      commit("PUSH_NEW_CONTACT", newContact)
    }
  },
}

export const mutations = {
  SET_ALL(state, all) {
    state.all = all
  },
  PUSH_NEW_CONTACT(state, newContact) {
    state.all = [...state.all, newContact]
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_PREV_GROUP_ID(state, prevGroupId) {
    state.prevGroupId = prevGroupId
  },
}
