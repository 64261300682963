import Vue from "vue"

// Pattern for storing active data sources for use by sibling components or components that do not have direct access to the data source dropdown componet
// activeSource = { key: value } where key is the DSD's component id and the value is the active data source object

export const state = {
  activeSource: {},
}

export const getters = {
  getActiveSource: (state) => (key) => {
    return _.get(state.activeSource, key)
  },
}

export const actions = {
  setActiveSource: ({ commit }, payload) => {
    const cache = "activeSource"
    payload.cache = cache
    commit("SET_CACHE", payload)
  },
}

export const mutations = {
  SET_CACHE: (state, payload) => {
    const cache = _.get(payload, "cache")
    const key = _.get(payload, "key")
    const value = _.get(payload, "value")
    if (cache && key) {
      Vue.set(state[cache], key, value)
    }
  },
}
