import axios from "axios"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
  create: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .post("attachments", params)
        .then((response) => {
          resolve(response.data.attachment)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
          commit("INCREMENT_RELOAD_KEY")
        })
    })
  },
  fetch: ({ commit, getters }, { id }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`attachments/${id}`)
        .then((response) => {
          const attachment = response.data.attachment
          resolve(attachment)
        })
        .catch((e) => {
          console.error(e)
          reject(null)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
}
